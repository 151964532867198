import styled from 'styled-components'

import Image from 'next/image'

export const StyledImage = styled(Image)`
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  height: auto;
`
