import React, { forwardRef } from 'react'

import { StyledTitle } from './styles'

const Title = (props, forwardedRef) => (
  <StyledTitle
    $title
    ref={forwardedRef}
    {...props}>
    <span data-text={props.children}>
      {props.children}
    </span>
  </StyledTitle>
)

export default forwardRef(Title)
