import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const helpers = css`
  font-weight: ${styledMap`
    light: 300;
    medium: 500;
    bold: 700;
    default: 400;
  `};
  text-align: ${styledMap`
    center: center;
    right: right;
    default: inherit;
  `};
  font-family: ${({
    theme,
    $title
  })=> !!$title ? theme.fonts.title : theme.fonts.text };

  ${({ uppercase }) => uppercase && 'text-transform: uppercase;' };

  ${({
    theme,
    typo
  }) => typo && theme.typo(typo || 'paragraph1')};
`

const base = css`
  ${helpers}
  font-style: normal;
`

export const labelButton = css`
  font-weight: 700;
  text-transform: ${({ uppercase }) => uppercase ? 'uppercase' : styledMap`
    default: uppercase;
    $secondary: uppercase;
    $noUppercase: none;
  `};
  ${({
    theme, typo
  }) => theme.typo(typo || 'button1')};
  z-index: 1;
`

export const ButtonLabel = styled.span`
  ${base};
  ${labelButton};
  margin: ${styledMap`
    default: 0;
    iconStart: 0 0 0 ${({ theme }) => theme.rem(12)};
    iconEnd: 0 ${({ theme }) => theme.rem(12)} 0 0;
  `};
`

export const LinkLabel = styled.span`
  ${base};
  ${({ theme }) => theme.typo('link1')};
`

export const Heading = styled.div`
  ${base};
  ${({
    theme,
    as
  }) => theme.typo(as || 'div')};
  ${({
    theme, typo
  }) => theme.typo(typo || 'h1')};
`

export const Paragraph = styled.p`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'paragraph1')};

  strong {
    font-weight: 700;
  }
`

export const StyledLink = styled.a`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'link1')};
`

export const Label = styled.div`
  ${base};
  ${({
    theme, typo
  }) => theme.typo(typo || 'label1')};
`
