import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <rect width="24.515" height="24.515" x=".544" y=".485" fill="#F5F9FE" rx="12.257"/>
    <g clipPath="url(#a)">
      <path fill="#00305C" fillRule="evenodd" d="M14.485 7.92c.283-.049.6-.065.9-.015.532.09 1.025.338 1.414.71.254.006.504-.045.726-.116a2.987 2.987 0 0 0 .611-.28l.006-.003a.418.418 0 0 1 .58.578c-.116.176-.276.489-.456.84l-.086.167c-.104.201-.21.404-.308.578a4.13 4.13 0 0 1-.195.316v.152a6.878 6.878 0 0 1-4.281 6.42 6.877 6.877 0 0 1-2.663.507 6.864 6.864 0 0 1-3.73-1.098.418.418 0 0 1 .278-.765 4.16 4.16 0 0 0 2.247-.358 2.706 2.706 0 0 1-1.453-1.628.418.418 0 0 1 .087-.408 2.71 2.71 0 0 1-.904-2.003v-.03a.418.418 0 0 1 .308-.403 2.735 2.735 0 0 1 .076-2.574.418.418 0 0 1 .687-.056 6.067 6.067 0 0 0 3.903 2.19 2.7 2.7 0 0 1 1.428-2.455c.223-.119.52-.214.825-.266Zm-6.284 4.205a1.866 1.866 0 0 0 1.395 1.205.418.418 0 0 1 .04.81 2.61 2.61 0 0 1-.465.096 1.868 1.868 0 0 0 1.444.724.418.418 0 0 1 .25.747c-.585.46-1.26.78-1.977.944.594.19 1.216.288 1.846.287h.005a6.038 6.038 0 0 0 6.102-6.086v-.299c0-.101.037-.199.104-.275a2.21 2.21 0 0 0 .2-.312c.09-.158.189-.347.291-.547l.02-.037c-.268.059-.57.089-.888.055a.418.418 0 0 1-.263-.133 1.866 1.866 0 0 0-1.06-.575c-.181-.03-.4-.023-.619.014a2.01 2.01 0 0 0-.572.18 1.867 1.867 0 0 0-.935 2.084.418.418 0 0 1-.428.515 6.906 6.906 0 0 1-4.54-2.02 1.86 1.86 0 0 0 .793 1.919.418.418 0 0 1-.243.765 2.662 2.662 0 0 1-.5-.061Z" clipRule="evenodd"/>
    </g>
    <defs><clipPath id="a"><path fill="#fff" d="M6.116 6.057h13.372v13.372H6.116z"/></clipPath></defs>
  </svg>
)
