import React from 'react'

import Link from '@/atoms/Link'

import {
  StyledMenuFooterMain,
  StyledMenuFooterMainUl
} from './styles'

const MenuFooterMain = ({
  items,
  cluster,
}) => (
  items &&
  !!items.length &&
  <StyledMenuFooterMain>
    <StyledMenuFooterMainUl>
      {
        items.map(({

          label,
          ...rest
        }, idx) => (
          <li key={`item_${idx}_${cluster.toLowerCase().replace(/\s+/g, '-')}`}>
            <Link
              {...rest}>
              {label}
            </Link>
          </li>
        )
        )
      }
    </StyledMenuFooterMainUl>
  </StyledMenuFooterMain>
)

export default MenuFooterMain
