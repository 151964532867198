import styled from 'styled-components'
import styledMap from 'styled-map'

import Link from '@/atoms/Link'

export const StyledMenuItemLink = styled(Link)`
  display: table;
  margin: 0 auto;
  transition: color ease-in-out .4s;
  text-align: left;
  width: 100%;
  padding-left: ${({ theme }) => theme.rem(20)};
  color: ${styledMap`
    withIndex: ${({ theme }) => theme.colors.primary.default()};
    default: ${({ theme }) => theme.colors.secondary.default};
  `};

  &:hover{
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediaQuery.md(`
    padding-left: 0;
    text-align: left;
    width: auto;
  `)}

  > span{
    ${({ theme }) => theme.typo('menuItem')};

    ${({ theme }) => theme.mediaQuery.lg(`
      font-size: 1.5625vw;
      line-height: 1.4;
    `)}
    font-weight: 700;
    font-family: ${({ theme })=> theme.fonts.title};
  }

  .label{
    position: relative;
    z-index: 2;
  }

  ${({
    withIndex,
    theme
  }) => withIndex && `
      position: relative;
      .number {
        font-family: ${theme.fonts.title};
        color: ${theme.colors.grayscale.white()};
        position: absolute;
        text-transform: uppercase;
        font-size: 30px;
        line-height: 30px;
        left: 0;
        z-index: 0;
        bottom: -2px;

        ${theme.mediaQuery.md(`
          left: -30px;
        `)}

        ${theme.mediaQuery.lg(`
        font-size: clamp(24px,3.5vw,50px);
          line-height: 100%;
          left: -50px;
          bottom: -13px;
        `)}

        ${theme.mediaQuery.xl(`
          font-size: clamp(48px,4.5vw,70px);
          left: -60px;
          bottom: -10px;
        `)}
      }
  `}
`

export const StyledMenuList = styled.ul`
  place-self: center;
  margin: 0;
  list-style: none;
  width: 100%;
  padding-top: ${({ theme })=> theme.rem(48)};
  padding-inline-start: 0;

  ${({ theme })=> theme.mediaQuery.md(`
    padding-top: ${theme.rem(24)};
  `)}

  > * + * {
    margin-block-start: ${({ theme })=> theme.rem(32)};

    ${({ theme })=> theme.mediaQuery.md(`
      margin-block-start: ${theme.rem(32)};
    `)}

    ${({ theme })=> theme.mediaQuery.lg(`
      margin-block-start: clamp(32px,  2.6041666667vw, 40px);
    `)}
  }

  li {
    display: block;
  }
`


export const StyledMenuHeader = styled.nav`
  overflow-y: auto;
  overscroll-behavior: contain;
  scrollbar-width: none;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  display: table;
  margin-bottom: ${({ theme }) => theme.rem(32)};
  /* display: flex;
  align-items: center;
  justify-content: center; */

  ${({ theme })=> theme.mediaQuery.md(`
    height: auto;
    margin-bottom: 0;
    display: grid;
    flex-shrink: 0;
  `)}

  &::-webkit-scrollbar { display: none };
  `

export const StyledMenuFooter = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  margin-top: ${({ theme }) => theme.rem(64)};
  row-gap: ${({ theme }) => theme.rem(20)};

  ${({ theme }) => theme.mediaQuery.lg(`
    flex-direction: row;
    align-items: flex-start;
    ${theme.paddingX(theme.rem(50))};
  `)}
`

export const StyledMenuFooterSingle = styled.div``
