import React from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

const getMetaImage = (image, facebook, twitter) => {
  const defaultImage = image && image.data ? image.data.attributes.url : null

  const socialImages = {
    facebook: !!facebook && facebook.image && facebook.image.data ? facebook.image.data.attributes.url : defaultImage,
    twitter: !!twitter && facebook.image && twitter.image.data ? twitter.image.data.attributes.url : defaultImage,
  }

  return { ...socialImages }
}

const Metadata = ({
  defaultSeo = {},
  metaSocial = []
}) => {
  const {
    asPath,
    locale,
    defaultLocale
  } = useRouter()

  const canonicalURL = `${process.env.NEXT_PUBLIC_SITE_URL}${locale !== defaultLocale ? `/${locale}` : ''}${asPath}`

  const {
    title,
    description,
    image
  } = defaultSeo

  const sanitizedTitle = title ? title.replace('<br/>', '') : ''

  const socials = metaSocial.reduce((acc, curr) => {
    acc[curr.socialNetwork.toLowerCase()] = { ...curr }
    return acc
  }, {})

  const images = getMetaImage(image, socials.facebook, socials.twitter)

  return (
    <Head>
      <title>{sanitizedTitle}</title>
      <meta name="description" content={description} />

      {!!canonicalURL && <link rel="canonical" href={canonicalURL} />}

      {
        socials.facebook &&
        <>
          {!!canonicalURL && <meta property="og:url" content={canonicalURL} />}
          <meta property="og:title" content={socials.facebook.title} />
          <meta property="og:description" content={socials.facebook.description} />
          <meta property="og:type" content="website" />
        </>
      }

      {
        socials.twitter &&
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content={socials.twitter.title} />
          <meta property="twitter:description" content={socials.twitter.description} />
        </>
      }

      { images.facebook && <meta property="og:image" content={images.facebook} /> }
      { images.twitter && <meta property="twitter:image" content={images.twitter} /> }

      <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/images/favicon/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/images/favicon/apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/images/favicon/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/images/favicon/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/images/favicon/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/images/favicon/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/images/favicon/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/images/favicon/apple-touch-icon-152x152.png" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-128.png" sizes="128x128" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-196x196.png" sizes="196x196" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-square70x70logo" content="/images/favicon/mstile-70x70.png" />
      <meta name="msapplication-TileImage" content="/images/favicon/mstile-144x144.png" />
      <meta name="msapplication-square150x150logo" content="/images/favicon/mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="/images/favicon/mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="/images/favicon/mstile-310x310.png" />

      <meta id="seodata-metatag" httpEquiv="content-language" content="it" />
      <meta name="theme-color" content="#ffffff" />
      {
        process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION &&
        <meta name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION} />
      }
      
      <meta name="facebook-domain-verification" content="u7bhayvtp3phc4sh6s2vy6ny77bgv6" />

    </Head>
  )
}

export default Metadata
