import styled from 'styled-components'

export const StyledWithHeading = styled.header`
  > div{
    margin-block-start: ${({ theme }) => theme.rem(30)};
    margin-block-end: ${({ theme }) => theme.rem(50)};
  }
`

export const StyledWithHeadingHeader = styled.div`
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;

  ${({
    theme, full = false
  }) => !full && theme.mediaQuery.lg(`
    max-width: 60%;
  `)}

  .Heading_title{
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .Heading_icon{
    max-width: 100px;
    width: 100%;
    position: relative;
    z-index: 0;
    transform: translateX(-20px);

    ${({ theme }) => theme.mediaQuery.lg(`
      max-width: 150px;
    `)}
  }
`
