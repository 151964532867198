import React from 'react'


import { StyledMenuFooterHeader } from './styles'

const MenuFooterHeader = ({ title }) => {
  return (
    <StyledMenuFooterHeader>
      {title}
    </StyledMenuFooterHeader>
  )
}

export default MenuFooterHeader
