import styled from 'styled-components'
import styledMap from 'styled-map'
import colors from 'theme/colors'
import { Heading } from '@/atoms/Typography'

export const StyledTitle = styled(Heading)`
  font-weight: 700;
  position: relative;
  padding-top: ${styledMap('as', {
    default: 0,
    h1: '3.125rem',
    h2: '3.125rem',
  })};

  color: ${styledMap('as', {
    default: colors.primary.default(),
    h4: colors.secondary.default,
  })};

  &::before{
    content: '';
    height: 10px;
    width: 75px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) =>theme.colors.secondary.default };

    display: ${styledMap('as', {
    default: 'none',
    h1: 'block',
    h2: 'block',
  })};
  }

  ${({ $decorator }) => !$decorator && '&::before{ display: none; }'};
  ${({ $noPadding }) => $noPadding && 'padding-top: 0;'};


  span{
    position: relative;
    display: table;

    &::before{
      -webkit-text-fill-color: currentcolor;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: ellipse(0% 150% at 0% -100%);
      color: #003a80;
      content: attr(data-text);
      transition: all 1s ease-out;
    }
  }

  .inView & span::before{
    clip-path: ellipse(160% 100% at 0% 50%)
  }

  ${({
    outline, animated
  }) => (outline || animated) && `
    span{
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px;
    }
  `}


`
