import styled, { css } from 'styled-components'

export const resetButton = css`
  width: auto;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  color: inherit;
  border: none;
  background: transparent;
  line-height: normal;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`

export const StyledButton = styled.button`
  ${resetButton}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 0 0;

  // ICONS
  && i {
    margin-right: ${({
    theme, label, icon
  }) => label && icon ? theme.rem(8) : 0};
  }

  // DISABLED
  &[disabled],
  &[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.3;
  }

  // VARIANT FULL WIDTH
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}
`
