/**
 * Accessibility specifications
 *
 * @url Accessibility https://www.w3.org/WAI/ARIA/apg/patterns/button/
 * @url Docs https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 *
 * TODO:
 * - button type props management => 'submit' value in props 'type' in case of <button type="submit">
 * - 'aria-pressed' props management
 * - probably 'aria-hidden="true"' and 'focusable="false"' on <Icon> components
 */

import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import { StyledButton } from './styles'
import { ButtonLabel } from '../Typography'

const Button = ({
  children,
  onClick = null,
  onKeyDown,
  useIconColor,
  ...props
}) => {
  const {
    typo,
    label,
    iconStart,
    iconEnd,
    $primary,
    $secondary,
    $title,
    uppercase,
    hideLabel = false,
    type = 'button',
  } = props

  // Button label props
  const buttonLabelProps = Object.assign(
    {},
    props.$title ? { $title } : null,
    props.$primary ? { $primary } : null,
    props.typo ? { typo } : null,
    props.$secondary ? { $secondary } : null,
    props.iconStart ? { iconStart } : null,
    props.iconEnd ? { iconEnd } : null,
    props.uppercase ? { uppercase } : null,
  )

  const buttonProps = {
    ...props,
    role: 'button',
    type,
    tabIndex: props.tabIndex || 0,
    'aria-label': props.label ? props.label : props.iconStart?.icon || props.iconEnd?.icon
  }


  if (props.disabled) {
    Object.assign(buttonProps, {
      'aria-disabled': true,
      tabIndex: '-1'
    })
  }

  if (onClick && onClick instanceof Function) Object.assign(buttonProps, { onClick })
  if (onKeyDown && onKeyDown instanceof Function) Object.assign(buttonProps, { onKeyDown })

  return (
    <StyledButton
      {...props}
      {...buttonProps}
    >
      {iconStart && <Icon icon={iconStart} useIconColor={useIconColor} />}
      {
        (label || children)
        && !hideLabel
        && (
          <ButtonLabel {...buttonLabelProps}>
            {label && label}
            {children && children}
          </ButtonLabel>
        )
      }
      {(iconEnd) && <Icon icon={iconEnd} useIconColor={useIconColor} />}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  iconStart: PropTypes.string,
  iconEnd: PropTypes.string,
  $primary: PropTypes.bool,
  $secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
}

export default Button
