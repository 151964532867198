import { css } from 'styled-components'
import { rem } from './helpers'

const configs = []

const GRID_CUSTOM_CONF = 'customGridConf'
export const GRID_DIMENSIONS = ['xs', 'md', 'xxl']
export const GRID_BASE_CONF = {
  mediaQuery: 'only screen',
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  },
  gutterWidth: {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24,
  },
  paddingWidth: {
    xs: 40,
    sm: 170,
    md: 150,
    lg: 170,
    xl: 170,
    xxl: 170,
  },
  marginWidth: {
    xs: 0,
    sm: 16,
    md: 0,
    lg: 16,
    xxl: 'auto',
  },
  container: {
    xs: 'full',
    sm: 540,
    md: 'full',
    lg: 960,
    xl: 1140,
    xxl: 1920,
  },
  breakpoints: {
    xs: 1,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1920,
  }
}

function makeMedia(media) {
  return (...args) => css`
    @media ${media} {
      ${css(...args)}
    }
  `
}

const hasCustomConf = (props) => JSON.stringify((props.theme && props.theme[GRID_CUSTOM_CONF]) || {})

const resolveConfig = (props) => {
  const themeConf = (props.theme && props.theme[GRID_CUSTOM_CONF]) || {}

  const conf = {
    ...GRID_BASE_CONF,
    ...themeConf,
  }

  conf.media = Object.keys(conf.breakpoints).reduce((media, breakpoint) => {
    const breakpointWidth = conf.breakpoints[breakpoint]
    media[breakpoint] = makeMedia(
      [
        conf.mediaQuery,
        breakpointWidth >= 0 && `(min-width: ${rem(breakpointWidth)})`,
      ]
        .filter(Boolean)
        .join(' and ')
    )
    return media
  }, {})

  return conf
}

export default function gridConfig(props = {}) {
  const customConf = hasCustomConf(props)
  if (configs[0] === customConf) {
    return configs[1]
  }

  const conf = resolveConfig(props)

  configs[0] = customConf
  configs[1] = conf

  return conf
}
