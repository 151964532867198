import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'
import CustomImg from '@/atoms/Image'


import { StyledLogo } from './styles'


const Logo = ({
  logoImage,
  className,
  style
}) => {
  const { generalOptions } = useContext(GeneralContext)
  const {
    isHomepage
  } = generalOptions


  // if ($hideInHomepage && isHomepage) return <></>

  return (
    logoImage &&
      <StyledLogo
        style={style}
        className={className}
        href="/"
      >
        <CustomImg alt="Lactalis Italia" {...logoImage}/>
      </StyledLogo>
  )
}


export default Logo
