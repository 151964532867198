import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'

import Icon from '@/atoms/Icon'

import {
  StyledSocials,
  StyledSocial
} from './styles'


const Socials = ({ className }) => {
  const { generalOptions } = useContext(GeneralContext)
  const { socials } = generalOptions

  return (
    socials &&
    !!socials.length &&
    <StyledSocials className={className}>
      {
        socials.map(({
          id,
          icon,
          label,
          url
        }, i) => (
          <li key={icon + i + id}>
            <StyledSocial href={url} aria-label={label}>
              <Icon icon={icon} unlockedProportions />
            </StyledSocial>
          </li>
        ))
      }
    </StyledSocials>
  )
}

export default Socials
