import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import {
  Lenis, useLenis
} from '@studio-freight/react-lenis'

import { AnimatePresence } from 'framer-motion'

import Metadata from '@/organisms/Metadata'
import Header from '@/organisms/Header'
import Footer from '@/organisms/Footer'

const LateralNavigation = dynamic(() => import('@/molecules/LateralNavigation'), { ssr: false })


const DefaultLayout = ({
  currentEntity,
  metadata,
  children
}) => {
  const lenis = useLenis()
  const router = useRouter()

  useEffect(() => {
    function goToHash(url) {
      const target = url.split('#').pop()
      const destination = `section[data-target="${target}"]`
      const scrollToEL = document.querySelector(destination)
      lenis.scrollTo(scrollToEL)
    }

    if (lenis) {
      goToHash(router.asPath)
    }

    Router.events.on('hashChangeStart', goToHash)

    return () => {
      Router.events.off('hashChangeStart', goToHash)
    }
  }, [lenis])

  return (
    <AnimatePresence
      onExitComplete={ () =>  {
        if (!lenis) return
        lenis.scrollTo(0, { immediate: true })
      }}
    >
      <Lenis root>
        <Metadata {...metadata} />
        <Header key="header" currentEntity={currentEntity} />
        <LateralNavigation key="lateralNavigation" />
        {children}
        <Footer key="footer" />
      </Lenis>
    </AnimatePresence>
  )
}

export default DefaultLayout
