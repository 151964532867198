import styled, { css } from 'styled-components'

export const Spacer = styled.div`
  ${({
    theme, xs, lg
  }) => css`
    ${typeof xs === 'number' && `height: ${theme.spacing(xs)};`}

    ${typeof lg === 'number' && `
      ${theme.mediaQuery.lg(`
        height: ${theme.spacing(lg)};
      `)}
    `}
  `}
`

export const Page = styled.main`
  padding: 10rem;
`

export const Section = styled.section`
  display: inline-block;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary.default()};
  background-color: ${({ theme }) => theme.colors.background.beige};
  position: relative;
  z-index: 0;
  &[data-section="hero"]{
    z-index: -1;
  }
  &[data-section="storyline"],
  &[data-section="headquarters"],
  &[data-section="mapwithpercents"]{
    overflow: hidden;
  }

  &[data-section="wave"]{
    display: table;
  }
`

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const DividerWrapperItem = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }
`
