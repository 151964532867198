import React from 'react'

import { Container } from '@/atoms/Layout/Grid'
import WithHeading from '@/molecules/WithHeading'

const SectionContainer = ({
  $fluid = false,
  heading = null,
  style = null,
  children
}) => (
  <Container
    $fluid={$fluid}
    style={style}
  >
    {
      heading &&
      <WithHeading heading={heading} />
    }

    <main>
      {children}
    </main>
  </Container>
)


export default SectionContainer
