import styled from 'styled-components'
import { helpers } from '@/atoms/Typography'
export const StyledRichtext = styled.div`
  ${helpers}

  strong,
  b {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.secondary.default}
  }

  &.Heading_subtitle{
    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 60%;
    `)}
  }
`
