import React from 'react'
import PropTypes from 'prop-types'

import glyphs from './glyphs'
import CustomImage from '../Image'
import { StyledIcon } from './styles'

const Icon = ({
  icon,
  ...props
}) => {
  let content = <></>
  let ariaLabel = null
  let wrapper = 'i'
  if (typeof icon === 'string') {
    const Glyph = glyphs[icon]
    content = Glyph ? <Glyph /> : null
    ariaLabel = icon
  }

  if (icon.data) {
    wrapper = 'div'
    content = <CustomImage {...icon} />
    ariaLabel = icon.data.attributes.alternativeText
  }

  return (
    <StyledIcon as={wrapper} {...props} aria-label={ariaLabel}>
      {content}
    </StyledIcon>
  )
}

Icon.propTypes = {
  // icon: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  color: PropTypes.string
}

export default Icon
