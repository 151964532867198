import styled from 'styled-components'

export const StyledMenuButton = styled.div`
  position: relative;
  margin-left: auto;
  pointer-events: auto;

  button{
    color: ${({ theme }) => theme.colors.grayscale.white()};
    position: relative;
    min-width: 44px;

    &:before{
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-47%,-54%);
      ${({ theme }) => theme.size(106)};
      background-color: ${({ theme }) => theme.colors.primary.default()};

      ${({ theme }) => theme.mediaQuery.md(`
        transform: translate(-47%,-54%);
        ${theme.size(210)};
      `)}
    }

    &:hover{
      #head path {
        d: path("M -153.805 -24.055 C -153.805 -24.055 -146.611 6.001 -215.344 15.487 C -224.638 16.416 -233.002 10.84 -234.861 5.264 C -235.79 -0.312 -227.426 -40.274 -228.355 -47.708 C -229.285 -55.143 -232.073 -55.143 -222.779 -67.224 C -226.496 -74.659 -231.795 -94.523 -229.007 -97.311 C -226.219 -100.099 -202.985 -86.158 -202.985 -86.158 C -202.985 -86.158 -210.42 -110.321 -206.703 -114.968 C -202.056 -119.615 -190.904 -99.169 -185.328 -97.311 C -161.64 -79.984 -100.791 -136.75 -85.963 -141.152 C -72.717 -145.084 157.523 -142.081 163.099 -141.152 C 182.615 -139.293 224.435 -76.098 208.636 -45.43 C 221.647 -38.924 235.587 -8.256 224.435 4.755 C 216.071 -12.903 205.848 -2.68 197.484 -14.762 C 189.12 -26.843 194.696 -39.854 208.636 -45.43 C 223.506 -71.451 188.191 -136.505 163.099 -141.152 C 163.099 -141.152 179.827 -103.978 176.11 -83.533 C 172.392 -63.087 159.382 -7.327 159.382 -7.327 C 159.382 -7.327 183.544 29.847 187.262 39.14 C 190.979 48.433 187.262 145.084 187.262 145.084 C 187.262 145.084 159.382 145.084 159.382 145.084 C 159.382 145.084 159.382 69.808 159.382 69.808 C 159.382 69.808 99.904 14.977 89.681 14.977 C 89.681 14.977 -27.415 14.977 -27.415 14.977 C -35.779 14.977 -47.916 39.138 -48.79 45.645 C -49.541 51.248 -58.083 145.084 -58.083 145.084 C -58.083 145.084 -85.963 145.084 -85.963 145.084 C -85.963 145.084 -90.61 59.585 -90.61 59.585 C -90.61 59.585 -99.904 32.635 -106.41 28.917 C -112.915 25.2 -139.865 8.472 -154.734 -7.327 C -154.734 -7.327 -152.876 -14.762 -153.805 -24.055 Z");
      }
    }

    span{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      ${({ theme })=> theme.typo('link2')};
      text-transform: none;
      font-family: ${({ theme })=> theme.fonts.title};
    }
  }


  svg{
    /* width: 100%;
    height: 100%; */
    /* width: 44px;
    height: 46px; */
    width: 30px;
    height: 32px;

    transform: scaleX(1);
    transition: transform ease-in-out .4s;

    ${({ theme }) => theme.mediaQuery.md(`
      width: 50px;
      height: 54px;
    `)}

    ${({ isOpen }) => isOpen && `
          transform: scaleX(-1);
    `}

    #head path{
      transition: d ease-in-out .6s;
    }
  }

`
