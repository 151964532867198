import styled from 'styled-components'

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: ${({ theme }) => theme.layers.header};

  .Logo_header,
  .Memu{
    transition: transform ease-in-out .4s;
  }

  .Logo_header img{
      max-width: 100px;
      ${({ theme }) => theme.mediaQuery.md(`
        max-width: 200px;
      `)}
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: ${({ theme }) => theme.colors.grayscale.white()};
    z-index: 0;
    transform: translateY(-100%);
    transition: transform ease-in-out .4s;
    ${({ theme }) => theme.mediaQuery.md(`
      height: 80px;
    `)}
  }

  ${({
    theme,
    scrolled,
    isOpen
  })=> scrolled && `
    .Logo_header,
    .Memu{
      transform: scale(.9) translateY(0);

      ${theme.mediaQuery.md(`
        transform: scale(.9) translateY(-48px);
      `)}
    }
    &::before{
      transform: translateY(0%);
      ${isOpen && 'transform: translateY(-100%);' }
    }
  `}
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${({ theme }) => theme.rem(16)};
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    padding: ${theme.rem(50)};
  `)}
`
