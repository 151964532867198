import React from 'react'

import { StyledRichtext } from './styles'

const Richtext = ({
  children,
  ...props
}) => {
  const injectHtml = (content) => {
    return { __html: content }
  }

  return (
    <StyledRichtext
      data-richtext-wrapper
      typo="paragraph1"
      {...props}
    >
      <span dangerouslySetInnerHTML={injectHtml(children)} />
    </StyledRichtext>
  )
}

export default Richtext
