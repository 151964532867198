import styled from 'styled-components'

export const StyledImageFramedImage = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  display: inline-block;

  img{
    max-width: 60%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
