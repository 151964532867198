import React from 'react'


import { Label } from '@/atoms/Typography'

import CustomImg from '@/atoms/Image'

import {
  StyledUnit,
  StyledUnitLink
} from './styles'

const Unit = ({
  title,
  url,
  image,
  label
}) => (
  <StyledUnit>
    {
      title &&
      <Label
        bold
        center
        as="strong"
        typo="paragraph1"
      >{title}</Label>
    }

    <StyledUnitLink href={url}>
      <CustomImg {...image} />
      {
        label &&
        <Label center bold as={'strong'} typo="link3" >{label}</Label>
      }
    </StyledUnitLink>

  </StyledUnit>
)

export default Unit
