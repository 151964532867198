import React from 'react'

export default () => (
  <svg width="48" height="48">
    <path fill="currentColor" d="M27.378 24.9h2.872v-5.023h-2.872V24.9Zm4.309 1.443h-5.744V18.44h5.744v7.903Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="M27.378 24.9h2.872v-5.023h-2.872V24.9Zm4.309 1.443h-5.744V18.44h5.744v7.903Z"/>
    <path fill="currentColor" d="m6.856 33.653 8.319 8.316 3.292-3.292-8.316-8.319-3.295 3.295ZM15.175 44 4.825 33.653l5.325-5.324 10.348 10.348L15.174 44Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="m6.856 33.653 8.319 8.316 3.292-3.292-8.316-8.319-3.295 3.295ZM15.175 44 4.825 33.653l5.325-5.324 10.348 10.348L15.174 44Z"/>
    <path fill="currentColor" d="m10.333 32.748-1.016 1.016 1.437 1.436 1.015-1.016-1.436-1.436Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="m10.333 32.748-1.016 1.016 1.437 1.436 1.015-1.016-1.436-1.436Z"/>
    <path fill="currentColor" d="M38.147 16.142H36.71v8.895h1.437v-8.895Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="M38.147 16.142H36.71v8.895h1.437v-8.895Z"/>
    <path fill="currentColor" d="M20.918 16.142h-1.436v8.895h1.436v-8.895Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="M20.918 16.142h-1.436v8.895h1.436v-8.895Z"/>
    <path fill="currentColor" d="m28.814 8.323 10.716 8.573 1.143-1.145-11.86-9.883-11.858 9.881 1.143 1.145 10.716-8.57ZM39.636 18.82l-10.822-8.658-10.822 8.658-3.156-3.164L28.822 4l13.986 11.655-3.172 3.164Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="m28.814 8.323 10.716 8.573 1.143-1.145-11.86-9.883-11.858 9.881 1.143 1.145 10.716-8.57ZM39.636 18.82l-10.822-8.658-10.822 8.658-3.156-3.164L28.822 4l13.986 11.655-3.172 3.164Z"/>
    <path fill="currentColor" d="M21.635 10.902v-5.25h1.437v4.134l1.436-1.117V4.216h-4.309v7.803l1.436-1.117Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="M21.635 10.902v-5.25h1.437v4.134l1.436-1.117V4.216h-4.309v7.803l1.436-1.117Z"/>
    <path fill="currentColor" d="M42.849 21.715c-.593-.593-1.473-.815-2.414-.59-1.575.366-3.018 1.771-3.743 3.621l-3.645 1.728a2.395 2.395 0 0 0-1.756-.72h-4.306c-.286 0-.838-.307-1.325-.578-.76-.422-1.546-.858-2.355-.858h-4.22c-2.036 0-3.738 1.58-4.667 2.445l-3.483 3.496.967 1.064c.854-.858 2.547-2.56 3.515-3.527.766-.713 2.192-2.037 3.667-2.037h4.22c.437 0 1.085.36 1.655.678.703.39 1.365.758 2.024.758h4.306c1.06 0 1.113 1.208 1.113 1.463 0 .33-.08 1.407-1.113 1.407h-7.501a.719.719 0 0 0 0 1.437h7.503c1.5 0 2.549-1.17 2.549-2.852a3.645 3.645 0 0 0-.108-.903l4.113-1.952.098-.285c.599-1.744 1.844-2.752 2.814-2.976.227-.06.465-.064.694-.012l-1.76 4.373-7.04 7.04c-.744.743-1.855 1.152-3.128 1.152h-8.605c-1.209 0-2.431.911-3.444 1.858l-5.125-5.131 5.847 6.431c1.175-1.139 2.092-1.721 2.721-1.721h8.614c1.656 0 3.127-.559 4.142-1.575l7.24-7.249 2.262-5.651-.326-.334Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth=".4" d="M42.849 21.715c-.593-.593-1.473-.815-2.414-.59-1.575.366-3.018 1.771-3.743 3.621l-3.645 1.728a2.395 2.395 0 0 0-1.756-.72h-4.306c-.286 0-.838-.307-1.325-.578-.76-.422-1.546-.858-2.355-.858h-4.22c-2.036 0-3.738 1.58-4.667 2.445l-3.483 3.496.967 1.064c.854-.858 2.547-2.56 3.515-3.527.766-.713 2.192-2.037 3.667-2.037h4.22c.437 0 1.085.36 1.655.678.703.39 1.365.758 2.023.758h4.307c1.06 0 1.113 1.208 1.113 1.463 0 .33-.08 1.407-1.113 1.407h-7.501a.719.719 0 0 0 0 1.437h7.503c1.5 0 2.549-1.17 2.549-2.852a3.645 3.645 0 0 0-.108-.903l4.113-1.952.098-.285c.599-1.744 1.844-2.752 2.814-2.976.227-.06.465-.064.694-.012l-1.76 4.373-7.04 7.04c-.744.743-1.855 1.152-3.128 1.152h-8.605c-1.209 0-2.431.911-3.444 1.858l-5.125-5.131 5.847 6.431c1.175-1.139 2.092-1.721 2.721-1.721h8.614c1.656 0 3.127-.559 4.142-1.575l7.24-7.249 2.262-5.651-.326-.334Z"/>
  </svg>
)
