import React from 'react'

import WithHeading from '@/molecules/WithHeading'
import Unit from '@/molecules/Unit'

import {
  StyledUnits,
  StyledUnitsList
} from './styles'

const Units = ({
  style,
  className,
  units,
  title,
}) =>  (

  <StyledUnits
    className={className}
    style={style}>
    {
      title &&
      <WithHeading
        center
        heading={{
          title,
          titleTag: 'h5'
        }}
      />
    }

    {
      units &&
      !!units.length &&
      <StyledUnitsList
      >
        {
          units.map(({
            id,
            ...rest
          }, idx) => <Unit key={`unit_${id}_${idx}`} {...rest} />)
        }
      </StyledUnitsList>
    }
  </StyledUnits>
)

export default Units
