import styled from 'styled-components'

export const StyledMenuFooterWrapper = styled.div ``

export const StyledMenuFooterHeader = styled.header`
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  margin-bottom: ${({ theme }) => theme.rem(4)};
  color: ${({ theme }) => theme.colors.primary.default()};
  ${({ theme }) => theme.typo('paragraph3')};

  ${({ theme })=> theme.mediaQuery.lg(`
    text-align: left;
  `)}

  a { text-decoration: none; }
`

export const StyledMenuFooterMain = styled.main``


export const StyledMenuFooterMainUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(4)};

  ${({ theme })=> theme.mediaQuery.lg(`
    align-items: flex-start;
    justify-content: flex-start;
  `)}

  a{
    ${({ theme }) => theme.typo('paragraph3')}
    color: ${({ theme }) => theme.colors.primary.default()};

    &:hover{
      text-decoration: underline;
    }
  }
`
