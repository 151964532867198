import { motion } from 'framer-motion'

const Layout = ({
  children,
  ...props
}) => (
  <motion.main
    initial={{ opacity: 0 }}
    animate={{  opacity: 1 }}
    exit={{ opacity: 0, }}
    transition={{
      duration: 0.6,
      type: 'spring'
    }}
    {...props}
  >
    {children}
  </motion.main>
)
export default Layout
