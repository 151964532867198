import React, { forwardRef } from 'react'
import { useRouter } from 'next/router'

import { LinkLabel } from '@/atoms/Typography'

import { StyledLink } from './styles'
import Link from 'next/link'

const SimpleLink = (props, forwardedRef) => {
  const { locale } = useRouter()

  const {
    children,
    href = null,
    target = '_self',
    disabled = false,
    withLabel = false,
    lang = null
  } = props

  const getRel = (attrs) => {
    if (attrs.target === '_blank') {
      return `${attrs.rel ? attrs.rel + ' ' : ''}noopener noreferrer`
    }
    return attrs.rel
  }

  const labelWrapper = withLabel ? <LinkLabel>{children}</LinkLabel> : <>{children}</>

  return (
    <StyledLink
      as={href ? Link : 'div' }
      {...props}
      ref={forwardedRef}
      href={href}
      locale={lang || locale}
      target={target}
      disabled={disabled}
      rel={getRel(props)}
    >
      {labelWrapper}
    </StyledLink>
  )
}

export default forwardRef(SimpleLink)
