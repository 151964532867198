import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'

import Units from '@/molecules/Units'
import Logo from '@/molecules/Logo'
import Socials from '@/molecules/Socials'
import MenuFooter from '@/molecules/Menus/MenuFooter'
import SectionContainer from '@/molecules/SectionContainer'
import { Paragraph } from '@/atoms/Typography'

import {
  StyledFooter,
  StyledFooterUpper
} from './styles'

const Footer = () => {
  const { generalOptions } = useContext(GeneralContext)
  const {
    companyName,
    unitLogos
  } = generalOptions

  return (

    <StyledFooter>
      <SectionContainer style={{
        paddingTop: 64, marginTop: 0
      }}>
        <StyledFooterUpper>
          <Logo className="Footer_logo" style={{ gridArea: 'logo' }} />
          <Paragraph className="Footer_company" bold typo="paragraph3" style={{ gridArea: 'company' }}>{companyName}</Paragraph>
          <Units units={unitLogos} className="Footer_units" style={{ gridArea: 'units' }} />
          <Socials className="Footer_socials" />
        </StyledFooterUpper>
        <MenuFooter />
      </SectionContainer>
    </StyledFooter>
  )
}

export default Footer
