import arrowDown from './arrow-down'
import arrowLeft from './arrow-left'
import arrowOblique from './arrow-oblique'
import bigSliderNext from './big-slider-next'
import chevronDown from './chevron-down'
import chevronLeft from './chevron-left'
import chevronRight from './chevron-right'
import chevronTop from './chevron-top'
import close from './close'
import error from './error'
import establishment from './establishment'
import facebook from './facebook'
import formAnswer from './form-answer'
import handHouse from './hand-house'
import instagram from './instagram'
import linkedin from './linkedin'
import mapLocation from './map-location'
import ovalDecoration from './oval-decoration'
import pin from './pin'
import play from './play'
import plus from './plus'
import search from './search'
import sliderNext from './slider-next'
import sliderPrev from './slider-prev'
import twitter from './twitter'
import user from './user'
import vehicles from './vehicles'
import youtube from './youtube'

export default {
  bigSliderNext,
  instagram,
  facebook,
  twitter,
  linkedin,
  youtube,
  plus,
  pin,
  user,
  mapLocation,
  search,
  vehicles,
  play,
  establishment,
  close,
  error,
  sliderNext,
  sliderPrev,
  'chevron-top': chevronTop,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'hand-house': handHouse,
  'arrow-down': arrowDown,
  'arrow-left': arrowLeft,
  'arrow-oblique': arrowOblique,
  'oval-decoration': ovalDecoration,
  'form-answer': formAnswer,
}
