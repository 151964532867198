import React, { useRef } from 'react'
import {
  useScroll, useTransform, motion
} from 'framer-motion'
import {
  StyledWave,
  StyledWaveContainer
} from './styles'

const Wave = ({
  id,
  position,
  as = 'div',
  className,
  template = 'primary',
  limit = '200px'
}) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end 50vh'],

  })
  const backgroundPosition = useTransform(
    scrollYProgress,
    [0, 1],
    ['0px', limit], { clamp: true, }
  )

  return (
    <StyledWaveContainer
      ref={ref}
      template={template}
      className={`${className ? className : ''} WaveContainer`}
      as={as}
      position={position}
    >
      <StyledWave
        style={{ backgroundPosition }}
        as={motion.div}
        bg={id}
      />
    </StyledWaveContainer>

  )
}

export default Wave
