import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledWaveContainer = styled.div`
  pointer-events: none;
  z-index: 1;
  /* left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 140%; */
  position: relative;
  z-index: 2;
  overflow: hidden;
  pointer-events: none;


  &::before,
  &::after{
    background-color: ${({
    theme,
    template
  }) => {
    if (template === 'primary') return theme.colors.grayscale.white()
    if (template === 'transparent') return 'transparent'
    return theme.colors.secondary.default
  }};
  }


  ${({
    position,
    theme,
    template
  }) => {
    if (position === 'top') {
      return `
      top: -2px;
      &::before{
        margin-bottom: -3px;
        content: '';
        height: 20px;
        width: 100%;
        display: block;

        ${theme.mediaQuery.md(`
          height: 100px;
        `)}
      }
    `
    }
    return `
      bottom: 99%;

      ${template !== 'transparent' && `
        &::after{
          margin-bottom: -3px;
          content: '';
          height: 20px;
          width: 100%;
          display: block;

          ${theme.mediaQuery.md(`
            height: 60px;
          `)}
        }
      `}
    `
  }}
`

export const StyledWave = styled.div`
  position: relative;
  width: 120%;
  margin-bottom: ${styledMap('bg', {
    bottomWave: '-3px',
    default: 0,
  })};

  height: ${styledMap('bg', {
    bottomWave: '57px',
    topWave: '77px',
    nextPageWave: '80px',
    heroWave: '64px',
    default: '85px',
  })};

  ${({ bg }) => bg && `
    background-image: url('/images/${bg}.svg');
    background-repeat: repeat-x;
    background-position:  0% 0%;
  `}

  background-size: ${styledMap('bg', {
    nextPageWave: 'auto 100%',
    topWave: 'auto 100%',
    default: 'auto'
  })};

`
