import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import { useRouter } from 'next/router'

import { MenuContext } from 'contexts/Menu'
import { GeneralContext } from 'contexts/General'
import Logo from '@/molecules/Logo'
import MenuButton from '@/molecules/Buttons/MenuButton'

import {
  StyledHeader,
  StyledHeaderContainer
} from './styles'

const Header = ({currentEntity}) => {
  const [scrolled, setScrolled] = useState(false)
  const router = useRouter()
  const { generalOptions } = useContext(GeneralContext)
  const { isHomepage, logoLactalis, logoLactalisWhite } = generalOptions
  const [logoImage, setLogoImage] = useState(logoLactalis)

  const {
    menuOpen,
    menuChangeHandler,
    menus = {},
  } = useContext(MenuContext)


  useEffect(() => {
    let ScrollTrigger

    const routesLogo = router.asPath !== '/contatti' && router.asPath !== '/cookie-policy' && router.asPath !== '/privacy-policy' && router.asPath !== '/note-legali' && router.asPath !== '/imballaggi-da-trasporto' && router.asPath !== '/compliance' && router.asPath !== '/dati-societari';


    const initTrigger = async () => {
      const section = document.querySelectorAll('[data-section]')
      ScrollTrigger = (await import('gsap/dist/ScrollTrigger')).ScrollTrigger
      ScrollTrigger.create({
        trigger: section[1],
        start: 'top top+=100',
        end: 99999,
        onToggle: self => setScrolled(self.isActive),
        onEnter: () => setLogoImage(logoLactalis),
        onLeaveBack: () => (currentEntity === 'pages' && routesLogo ? setLogoImage(logoLactalisWhite) : setLogoImage(logoLactalis)),
      })
    }

    if (!isHomepage) initTrigger()
    
    if(currentEntity === 'pages' && routesLogo){
      setLogoImage(logoLactalisWhite);
    }

    return () => {
      if (ScrollTrigger) {
        const triggers = ScrollTrigger.getAll()
        triggers.forEach((trigger) => trigger.kill())
      }
    }
  }, [router.asPath])

  const { header } = menus
  return (
    <StyledHeader isOpen={menuOpen} scrolled={scrolled}>
      <StyledHeaderContainer>
        { !menuOpen && isHomepage ?  <></> : menuOpen ? <Logo className="Logo_header"  logoImage={logoLactalis}/> : <Logo className="Logo_header"  logoImage={logoImage}/>} 
        <MenuButton
          menuLabel={header?.labelOpen}
          menuCancel={header?.labelCancel}
          menuOpen={menuOpen}
          menuChangeHandler={menuChangeHandler}
        />
      </StyledHeaderContainer>
    </StyledHeader>
  )
}

export default Header
