import styled from 'styled-components'

export const StyledFooter = styled.footer`
  /* padding:  0 ${({ theme }) => theme.rem(32)}; */

  ${({ theme }) => theme.mediaQuery.xl(`
    padding: 0 ${theme.rem(50)};
  `)}
`

export const StyledFooterUpper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-template-areas:
  "logo company"
  "units units"
  "socials socials";

  column-gap: ${({ theme })=> theme.rem(16)};
  row-gap: ${({ theme })=> theme.rem(32)};

  ${({ theme }) => theme.mediaQuery.lg(`
    grid-template-columns: 1fr 4fr 6fr 1fr;
    grid-template-areas: "logo company units socials";
    column-gap: ${theme.rem(24)}
  `)}

  .Footer_company{
    color: ${({ theme }) => theme.colors.primary.default()};
  }

  .Footer_logo{
    place-self: center;
    img{
      max-width: ${({ theme }) => theme.rem(80)};

      ${({ theme }) => theme.mediaQuery.lg(`
        max-width: ${theme.rem(175)};
      `)}
    }
  }

  .Footer_socials{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${({ theme }) => theme.rem(30)};
  }

  .Footer_units{
    margin: 0;
    > main{
      flex-direction: row;
      justify-content: center;
    }

    ${({ theme }) => theme.mediaQuery.lg(`
      ${theme.paddingX(35)};
      border-left: 1px solid ${theme.colors.primary.default()};
      border-right: 1px solid ${theme.colors.primary.default()};
    `)}
  }
`

export const StyledFooterLower = styled.div``
