import styled from 'styled-components'

import Link from '@/atoms/Link'

export const StyledUnit = styled.div`
  max-width: 200px;

  strong{
    display: block;
    color: ${({ theme }) => theme.colors.secondary.default};
    padding-bottom: ${({ theme }) => theme.rem(50)};
    position: relative;

    &::after{
      content: '';
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px dashed ${({ theme }) => theme.colors.secondary.default};
      height: 20px;
    }
  }
`

export const StyledUnitLink = styled(Link)`
  display: block;
  strong{
    margin-top: ${({ theme }) => theme.rem(20)};
    display: block;
    color: ${({ theme }) => theme.colors.primary.default()};
  }
`
