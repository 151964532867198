import styled from 'styled-components'
import Link from 'next/link'

export const StyledLogo = styled(Link)`
  display: table;
  pointer-events: auto;

  img{
    display: block;
    max-width: 200px;
    height: auto;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 175px;
    `)}
  }
`
