import React from 'react'
import { motion } from 'framer-motion'
import Richtext from '@/atoms/RichText'
import Title from '../Title'
import ImageFramed from '@/molecules/ImageFramed'

import {
  StyledWithHeading,
  StyledWithHeadingHeader
} from './styles'


const WithHeading = ({
  heading,
  $decorator = true,
  full,
  ...props
}) => (
  <StyledWithHeading>
    {
      heading.title &&
      <StyledWithHeadingHeader
        as={motion.div}
        full={full}
        viewport={{ once: true }}
        onViewportEnter={(entry) => {
          if (!heading.animated) return
          entry.target.classList.add('inView')
        }}
        onViewportLeave={(entry) => {
          if (!heading.animated) return
          entry.target.classList.remove('inView')
        }}
      >
        <Title
          className="Heading_title"
          $decorator={$decorator  }
          as={heading.titleTag}
          typo={heading.titleTag}
          animated={heading.animated}
          $title
          bold
          {...props}
        >
          {heading.title}
        </Title>

        {
          heading.icon &&
          heading.icon.data &&
          <ImageFramed className="Heading_icon" image={heading.icon }/>
        }
      </StyledWithHeadingHeader>
    }

    {
      heading.subtitle &&
      <Richtext
        className="Heading_subtitle">{heading.subtitle}</Richtext>
    }

  </StyledWithHeading>
)

export default WithHeading
