import React from 'react'

import { StyledMenuFooterWrapper } from './styles'

import MenuFooterHeader from './Header'
import MenuFooterMain from './Main'

const MenuFooterItem = ({
  header,
  main
}) => {
  return (
    <StyledMenuFooterWrapper>
      {
        header &&
        <MenuFooterHeader {...header}/>
      }

      {
        main &&
        <MenuFooterMain
          cluster={header?.title || ''}
          items={main}
        />
      }

    </StyledMenuFooterWrapper>
  )
}


export default MenuFooterItem
