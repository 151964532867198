import styled from 'styled-components'
import styledMap from 'styled-map'

const StyledFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({
    border,
    color,
    theme,
    square = false
  }) => {
    if (border && square) return `background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' stroke='${color ? color : theme.colors.grayscale.black()}' stroke-width='4' stroke-dasharray='2%2c 4' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");`
    return border && `background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width='100%' height='100%' fill='none' rx='100%' ry='100%' stroke='${color ? color : theme.colors.grayscale.black()}' stroke-width='4' stroke-dasharray='2,4' stroke-dashoffset='0' /%3e%3c/svg%3e");`
  }}

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}; `}
  ${({ background }) => background && `background: ${background}; `}

  ${({
    size,
    theme
  }) => size && theme.size(size) }

  border-radius:  ${styledMap`
    default: 50%;
    square: 0;
  `};

  aspect-ratio:  ${styledMap`
    default: 1;
    square: 16 / 9;
    auto: auto;
  `};


  ${({
    outerBorder,
    theme
  }) => outerBorder && `
    position: relative;
    padding: ${!!Number(outerBorder) && typeof outerBorder !== 'boolean' ? theme.rem(outerBorder) : theme.rem(16)};
  `}
`

export default StyledFrame
