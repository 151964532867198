import React from 'react'

import colors from 'theme/colors'
import CustomImage from '@/atoms/Image'
import Frame from '@/atoms/Frame'

import { StyledImageFramedImage } from './styles'

const ImageFramed = ({
  image,
  frameProps = { background: colors.grayscale.white() },
  ...props
}) => (
  <StyledImageFramedImage {...props}>
    <Frame {...frameProps} />
    <CustomImage {...image}/>
  </StyledImageFramedImage>
)

export default ImageFramed
