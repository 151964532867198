import styled from 'styled-components'

import Link from '@/atoms/Link'

export const StyledSocials = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  grid-area: socials;
`

export const StyledSocial = styled(Link)`
  display: inline-block;
  svg{
    height: 29px;
  }
`
