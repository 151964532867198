import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import gridConfig, { GRID_DIMENSIONS } from 'theme/gridConfig'

const Container = styled.div`
  max-width: 100%;
  box-sizing: border-box;

  ${(p) => !p.$fluid && css`
    ${GRID_DIMENSIONS.map((d) => gridConfig(p).container[d] && gridConfig(p).media[d]`
      padding-left: ${p.theme.rem(gridConfig(p).paddingWidth[d])};
      padding-right: ${p.theme.rem(gridConfig(p).paddingWidth[d])};
      margin-left: ${ typeof gridConfig(p).marginWidth[d] === 'number' ? p.theme.rem(gridConfig(p).marginWidth[d]) : gridConfig(p).marginWidth[d]};
      margin-right: ${ typeof gridConfig(p).marginWidth[d] === 'number' ? p.theme.rem(gridConfig(p).marginWidth[d]) : gridConfig(p).marginWidth[d]};
    `)}

    ${GRID_DIMENSIONS.map((d) => gridConfig(p).container[d] && gridConfig(p).media[d]`
      ${typeof gridConfig(p).container[d] === 'number' ? `max-width: ${p.theme.rem(gridConfig(p).container[d])};` : 'width: 100%;'}
    `)}
  `}

  ${({ $debug }) => $debug && css`
    background-color: ${'#' + ((1 << 24) * Math.random() | 0).toString(16)};
    outline: #fff solid 1px !important;
  `}

  ${({ theme }) => theme.marginY(60)};

`


Container.displayName = 'Container'

Container.propTypes = {
  $fluid: PropTypes.bool,
  children: PropTypes.node,
  $debug: PropTypes.bool,
}

export default Container
