import React, { useContext } from 'react'
import { MenuContext } from 'contexts/Menu'

import MenuFooterItem from './MenuFooterItem'

import {
  StyledMenuFooter,
  StyledMenuFooterSingle
} from './styles'

const MenuFooter = () => {
  const { menus = {}, } = useContext(MenuContext)

  const { footer } = menus

  return (
    footer &&
    footer.menus &&
    <StyledMenuFooter>
      {
        footer.menus.map(singleMenu => (
          <StyledMenuFooterSingle key={singleMenu.id}>
            <MenuFooterItem {...singleMenu} />
          </StyledMenuFooterSingle>
        ))
      }
    </StyledMenuFooter>
  )
}


export default MenuFooter
