import styled from 'styled-components'

export const StyledUnits = styled.div`
  display: table;
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.marginY([96, 0])};
  ${({ theme }) => theme.mediaQuery.md(theme.marginY(96))};

  header{
    margin-bottom: ${({ theme }) => theme.rem(32)};

    ${({ theme }) => theme.mediaQuery.md(`
      margin-bottom: ${theme.rem(50)};
    `)};
    .Heading_title span{
      margin: 0 auto;
    }
  }

  &.VideoUnits{
    main{
      row-gap: ${({ theme }) => theme.rem(60)};
      column-gap: ${({ theme }) => theme.rem(32)};
    }
  }
`

export const StyledUnitsList = styled.main`
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;



  ${({ theme }) => theme.mediaQuery.md(`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  `)};

`
