import React from 'react'
import { StyledImage } from './styles'

const CustomImage = ({
  data,
  format = null,
  fill = false,
  lazyload = true,
  ...rest
} = {}) => {
  if (!data) return <></>

  const { attributes } = data
  const {
    alternativeText,
    formats,
    width,
    height,
    url,
  } = attributes

  const imageUrl = formats && formats[format] ? formats && formats[format] : { url }

  let imageProps = {
    width,
    height,
    loading: 'lazy'
  }
  if (lazyload === false) {
    delete imageProps.loading
    imageProps.priority = true
  }

  if (fill) imageProps = {}
  return (
    <StyledImage
      src={imageUrl.url}
      alt={alternativeText}
      fill={fill}
      {...imageProps}
      {...rest}
    />
  )
}


export default CustomImage
